import React from "react";
import { IoArrowForwardCircleOutline } from "react-icons/io5";
import { Link as RouterLink } from "react-router-dom";
import {
  Flex,
  Link,
  Heading,
  Image,
  Box,
  Text,
  Button,
  Grid,
  Icon,
} from "@chakra-ui/react";
// import model1 from "../images/model1.png";
// import products from "../products";
import ProductCard from "./ProductCard";

const Category = () => {
  return (
    <Flex
      flexDirection="column"
      px="15%"
      alignItems="center"
      justifyContent="center"
      bg="#333333"
      paddingBottom="40px"
    >
      <Heading
      
        as="h2"
        marginY="40px"
        color="#f5f5f5"
        
        letterSpacing="widest"
      >
        PRODUCTS
      </Heading>
      <Grid
        templateColumns={["1fr 1fr","1fr 1fr", "1fr 1fr 1fr", "1fr 1fr 1fr 1fr"]}
        gap="30px"
      >
        <ProductCard
          name="BARCODE PRINTERS"
          image="/images/TSC TE244.jpg"
          url="/product/BARCODE PRINTERS"
        ></ProductCard>
        <ProductCard
          name="MOBILE PRINTER"
          image="images\HGRT HGS-80ML.png"
          url="/product/MOBILE PRINTER"
        ></ProductCard>
        <ProductCard
          name="THERMAL BILL PRINTERS"
          image="images\CITIZEN CT-S310 III.jpg"
          url="/product/THERMAL BILL PRINTERS"
        ></ProductCard>
        <ProductCard
          name="BARCODE SCANNERS"
          image="/images/RETSOL LS 450.jpg"
          url="/product/BARCODE SCANNERS"
        ></ProductCard>
        <ProductCard
          name="THERMAL TRANSFER RIBBONS"
          image="/images/THERMAL TRANSFER RIBBONS.jpg"
          url="/product/THERMAL TRANSFER RIBBONS"
        ></ProductCard>
        <ProductCard
          name="HAND LABELLERS"
          image="/images/HAND LABELLERS.jpg"
          url="/product/HAND LABELLERS"
        ></ProductCard>
        {/* <ProductCard
          name="SEQUENTIAL NUMBERING MACHINE"
          image="/images/TSC TE244.jpg"
          url="/product/sequential"
        ></ProductCard> */}
        <ProductCard
          name="BARCODE LABELS"
          image="/images/BARCODE LABELS.jpg"
          url="/product/BARCODE LABELS"
        ></ProductCard>
        <ProductCard
          name="GUN LABELS"
          image="/images/GUN LABELS.jpg"
          url="/product/GUN LABELS"
        ></ProductCard>
        {/* <ProductCard
          name="POS ROLLS"
          image="/images/pos.jpg"
          url="/product/pos"
        ></ProductCard> */}
        <ProductCard
          name="PRE INKED STAMPS"
          image="/images/PRE INKED STAMPS.jpg"
          url="/product/PRE INKED STAMPS"
        ></ProductCard>
        <ProductCard
          name="NOTE COUNTING MACHINE"
          image="/images/NOTE COUNTING MACHINE.jpg"
          url="/product/NOTE COUNTING MACHINE"
        ></ProductCard>
        {/* <ProductCard
          name="SELF ADHESIVE LABELS"
          image="/images/TSC TE244.jpg"
          url="/product/self-adhesive"
        ></ProductCard> */}
        <Flex fontSize="2vh" _hover={{ textDecor: "", color: "#df0000" }} gap="7px" alignItems="center" justifyContent="center" color="white">
          <Link _hover={{ textDecor: "", color: "#df0000" }} alignItems="center" as={RouterLink}  to="/product/BARCODE PRINTERS" >View More</Link>
        <Icon as={IoArrowForwardCircleOutline} w="5" h="5"   ></Icon></Flex>
      </Grid>
    </Flex>
  );
};

export default Category;
