import React from 'react'
import logo from "../android-chrome-512x512.png"
import { FloatingWhatsApp } from 'react-floating-whatsapp'

const Whatsapp = () => {
  return (
    <FloatingWhatsApp phoneNumber='+919167036524' accountName='Variant Labels' avatar={logo}  />
  )
}

export default Whatsapp