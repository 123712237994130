import {
  Flex,
  Link,
  Icon,
  Box,
  Heading,
  List,
  ListItem,
} from "@chakra-ui/react";
import { React, useState } from "react";
import { HiChevronDown, HiChevronUp } from "react-icons/hi";
import { Link as RouterLink } from "react-router-dom";
import Header from "./Header";

import HeaderLink from "./HeaderLink";

const LinkMenu = () => {
  const [showProduct, setShowProduct] = useState(false);
  return (
    <Box zIndex="9999">
      {/* display={{ base: show ? "block" : "none", md: "flex" }} */}
      <Header></Header>
      <Box
        color="white"
        // pos="fixed"
        zIndex="999"
        
        
        // pos="fixed"
        pos={{ base: "fixed" , lg: "sticky" }}
        top={{ base: "0" , lg: "80px" }}
        bg="#333333"
        scrollBehavior="auto"
        h={{ base: "auto", lg: "668px" }}
        w={{ base: "full", lg: "270px" , xl:"20vw"}}
      >
        <Flex
          flexDir="column"
          padding="6"
          mt="80px"
          alignItems="flex-start"
          justifyContent="center"
          paddingRight="10"
          paddingLeft="8"
        >
          <Flex
            onClick={() => setShowProduct(!showProduct)}
            alignItems="center"
            justifyContent="space-between"
            w="full"
          >
            <Heading
              // display={{ base: "none", lg: "block" }}

              textAlign="center"
              fontSize="2xl"
              letterSpacing="wide"
            >
              Products
            </Heading>
            <Icon
              as={showProduct ? HiChevronUp : HiChevronDown}
              display={{ base: "block", lg: "none" }}
              w="8"
              h="8"
            />
          </Flex>
          <List
            onClick={() => setShowProduct(!showProduct)}
            as={Flex}
            display={{ base: showProduct ? "block" : "none", lg: "flex" }}
            flexDir={{ base: "row", lg: "column" }}
            rowGap="3"
            mt="6"
            flexWrap="wrap"
          >
            <HeaderLink url="/product/BARCODE PRINTERS" label="BARCODE PRINTERS" color="f5f5f5"/>
            <HeaderLink url="/product/MOBILE PRINTER" label="MOBILE PRINTER" />
            <HeaderLink url="/product/THERMAL BILL PRINTERS" label="THERMAL BILL PRINTERS" />
            <HeaderLink url="/product/BARCODE SCANNERS" label="BARCODE SCANNERS" />
            <HeaderLink
              url="/product/THERMAL TRANSFER RIBBONS"
              label="THERMAL TRANSFER RIBBONS"
            />
            <HeaderLink url="/product/HAND LABELLERS" label="HAND LABELLERS" />
            {/* <HeaderLink
              url="/product/desktop"
              label="SEQUENTIAL NUMBERING MACHINE"
            /> */}
            <HeaderLink url="/product/BARCODE LABELS" label="BARCODE LABELS" />
            <HeaderLink url="/product/GUN LABELS" label="GUN LABELS" />
            {/* <HeaderLink url="/product/desktop" label="POS ROLLS" /> */}
            <HeaderLink url="/product/PRE INKED STAMPS" label="PRE INKED STAMPS" />
            <HeaderLink url="/product/NOTE COUNTING MACHINE" label="NOTE COUNTING MACHINE" />
            {/* <HeaderLink url="/product/desktop" label="SELF ADHESIVE LABELS" /> */}
          </List>
        </Flex>
      </Box>
    </Box>
  );
};

export default LinkMenu;
