import React from "react";
import About from "../components/About";
import Header from "../components/Header";
import HeroSection from "../components/HeroSection";
import Category from "../components/Category";
import  Contact  from "../components/Contact";
import { ContactUs } from "../components/ContactUs";
import Whatsapp from "../components/Whatsapp";
import Footer from "../components/Footer";

const HomeScreen = () => {
  return (
    < >
      <Header />
      <Whatsapp></Whatsapp>
      <HeroSection />
      <About id="about"/>
      <Category  />
      <Contact/>
      <Footer/>
      {/* <ContactUs/> */}
    </>
  );
};

export default HomeScreen;
