import {
  Box,
  Button,
  Flex,
  Heading,
  Icon,
  Image,
  Link,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
} from "@chakra-ui/react";
import { useState } from "react";
import { Link as ScrollLink, animateScroll as scroll } from "react-scroll";
import { HiMenuAlt3 } from "react-icons/hi";
// import { IoChevronDown } from "react-icons/io5";
// import { useDispatch, useSelector } from "react-redux";
import { Link as RouterLink, useNavigate } from "react-router-dom";

import HeaderLink from "./HeaderLink";
import { HashLink } from "react-router-hash-link";

const Header = () => {
  const [show, setShow] = useState(false);
  return (
    <Flex
      as="header"
      align="center"
      justifyContent="space-between"
      zIndex="9999"
      wrap="wrap"
      py="5"
      px="10%"
      bgColor="#f5f5f5"
      w="100%"
      pos="fixed"
      top="0"
      left="0"
      boxShadow="0px 0px 20px 0px rgba(255,0,0,1)"
    >
      {/* Logo/Title */}
      <Link as={RouterLink} to="/#home" smooth="true" >
        <Image src="/images/VLT LOGO WEB.png"
          height="45px" width="auto" smooth="true"
        >

        </Image>
        {/* <Heading
          as="h1"
          color="#f5f5f5"
          fontWeight="bold"
          size="md"
          letterSpacing="wide"
        >
          Variant Labels
        </Heading> */}
      </Link>

      {/* Hamburger Menu */}
      <Box onClick={() => setShow(!show)}>
        <Icon
          display={{ base: "block", md: "none" }}
          as={HiMenuAlt3}
          color="#df0000"
          w="6"
          h="6"
        />
      </Box>

      {/* Menu */}
      <Box
        display={{ base: show ? "block" : "none", md: "flex" }}
        width={{ base: "full", md: "auto" }}
        mt={{ base: "4", md: "0" }}
      >
        <HeaderLink  url="/#products" label="PRODUCTS" />
        <HeaderLink url="/#services" label="SERVICES" />
        <HeaderLink url="/#about" label="ABOUT US" />
      
        <Button
          as={HashLink}
          smooth="true"
          color="#f5f5f5"
          bg="#df0000"
          _hover={{ bg: "#f5f5f5", color: "#df0000" }}
          to="/#contactus"
        >
          CONTACT US
        </Button>

        {/* Admin Menu */}
      </Box>
    </Flex>
    // <Flex
    //   alignItems="center"
    //   justifyContent="space-around"
    //   height="70px"
    //   bg="gray.800"
    //   color="gray.50"
    //   width="100%"
    // >
    //   <Box>
    //     <Link as={RouterLink} to="/">
    //       <Image />
    //     </Link>
    //   </Box>
    //   <Flex alignItems="center" justifyContent="space-evenly">
    //     <HeaderLink url="#" label="PRODUCTS" />
    //     <HeaderLink url="#" label="SERVICES" />
    //     <HeaderLink url="#" label="ABOUT US" />
    //     {/* <HeaderLink as Button url="#" label="CONTACT US" /> */}
    //     <Link as={RouterLink} to="#">
    //       <Button>CONTACT US</Button>
    //     </Link>
    //   </Flex>
    // </Flex>
  );
};

export default Header;
