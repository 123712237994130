import React from "react";
// import hero_bg from "../images/hero_bg.jpg";
import { Box, Flex, Image, Heading, Button, Link } from "@chakra-ui/react";
// import {Link as BrowserRouter} from 'react-router-dom'
import ScrollToTop from "./ScrollToTop";

const HeroSection = () => {
  return (

    <Flex id="home"
      position="relative"
      mt="70px"
      backgroundImage={`url("/images/hero_bg.jpg")`}
      backgroundSize="cover"
      backgroundPosition="center"
      backgroundRepeat="no-repeat"
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
      color="#ffffff"
      width="100%"
      height={["30vh","70vh","70vh"]}
      textAlign="center"
    >
      <ScrollToTop></ScrollToTop>
      {/* <Image w="100%" src={hero_bg} alt="hero-bg" filter="brightness(70%)" /> */}
      <Heading
        position="relative"
        textAlign="centre"
        px={["10%","20%"]}
        fontSize={["xl", "3xl", "4xl", "5xl"]}
      >
        We are one of the leading providers of Barcode and Labelling Solutions.
      </Heading>
      <Button
        color="#f5f5f5"
        variant="outline"
        fontSize={["md", "lg", "xl", "2xl"]}
        colorScheme="#df0000"
        padding="25px"
        _hover={{ bg: "#df0000", color: "#f5f5f5" }}
        mt="5%"
      >
        Checkout Our Services
      </Button>
    </Flex>
  );
};

export default HeroSection;
