
import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';
import {
    FormControl,
    FormLabel,
    Heading,
    Button,
    Input,
    FormErrorMessage,
    FormHelperText,
    Box,
    Flex,
    Grid,
    AspectRatio,
} from '@chakra-ui/react'
import { Form } from 'react-router-dom';

const Contact = () => {
    const form = useRef();

    const sendEmail = (e) => {
        e.preventDefault();
    
        emailjs
          .sendForm('service_emshv08', 'template_81jaevu', form.current, {
            publicKey: 'ABF5WuIvYA20dEwO_',
          })
          .then(
            () => {
              console.log('SUCCESS!');
            },
            (error) => {
              console.log('FAILED...', error.text);
            },
          );
      };

    return (
        <Flex flexDirection="column"    marginY="40px"
            px={["0%", "0%"]} >
            <Heading id="contactus"
            marginX="auto"
                as="h2"
                color="#df0000"
                // textDecor="underline"
                letterSpacing="widest"
            >
                CONTACT US
            </Heading>
            <Grid mt="30px" px="50px" templateColumns={['1fr','1fr','1fr 1fr']} alignContent="center" alignItems="center" gap="50px"  >
            <AspectRatio width="100%" ratio={16 / 9} alignItems="center" alignContent="center">
            <iframe 
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d120710.07669941452!2d72.74803612453772!3d19.011352089710613!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7cf8ab52792b9%3A0xb7e8cae73548ed04!2sVARIANT%20LABELS%20%26%20TECHNOLOGIES!5e0!3m2!1sen!2sin!4v1711541495637!5m2!1sen!2sin"
                    loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
            </AspectRatio>
                
                <Flex alignItems="flex-start">
                <form ref={form} onSubmit={sendEmail}>
      <label>Name</label>
      <Input type="text" name="user_name" />
      <label>Email</label>
      <Input type="email" name="user_email" />
      <label>Message</label>
      <Input name="message" />
      <Input type="submit" value="Send" mt="20px" color="#f5f5f5"
          bg="#df0000"
          _hover={{ bg: "#f5f5f5", color: "#df0000" }}/>
    </form>
                    
                </Flex>
            </Grid>

            {/* <Grid gridTemplateRows="1fr,1fr" >
                
                <iframe 
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d120710.07669941452!2d72.74803612453772!3d19.011352089710613!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7cf8ab52792b9%3A0xb7e8cae73548ed04!2sVARIANT%20LABELS%20%26%20TECHNOLOGIES!5e0!3m2!1sen!2sin!4v1711541495637!5m2!1sen!2sin"
                    loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                
                <Flex><FormControl>
                    <FormLabel>Email address</FormLabel>
                    <Input type='email' />
                    <FormHelperText>We'll never share your email.</FormHelperText>
                </FormControl></Flex>
            </Grid> */}

        </Flex>
    )
}

export default Contact