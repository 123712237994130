import { BrowserRouter, Routes, Route } from "react-router-dom";
import Header from "./components/Header";
import HomeScreen from "./screens/HomeScreen";
import ProductScreen from "./screens/ProductScreen";
import { FloatingWhatsApp } from 'react-floating-whatsapp'
import ScrollToTop from "./components/ScrollToTop";


function App() {
  return (
    <BrowserRouter>
    <ScrollToTop>
    <Routes>
      
      <Route path="/" element={<HomeScreen />}></Route>
      <Route path="/product/:category" element={<ProductScreen />} />
    </Routes>
    </ScrollToTop>
      
    </BrowserRouter>
  );
}

export default App;
