import { Flex, Grid,Heading,Input,Image, List, ListItem, Text } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';
import HeaderLink from './HeaderLink';

const Footer = () => {
	const form = useRef();

    const sendEmail = (e) => {
        e.preventDefault();
    
        emailjs
          .sendForm('service_emshv08', 'template_81jaevu', form.current, {
            publicKey: 'ABF5WuIvYA20dEwO_',
          })
          .then(
            () => {
              console.log('SUCCESS!');
            },
            (error) => {
              console.log('FAILED...', error.text);
            },
          );
      };
	return (
		<Flex fontSize="xs" fontWeight="100" direction="column"  as='footer'  bg="#333333" color={'white'} justifyContent='center' alignItems="center" py='5'>
			<Grid mx="10%" mt="10px" templateColumns={["1fr 1fr","1fr 1fr 1fr"]} gap="40px">
				<Flex gap="5px" direction="column" >
					<Image src="/images/VLT LOGO WEB white.png" width="200px"mb="10px" />
		  <Text color="#c9c9c9" >2A, BYCULLA SERVICE INDL. ESTATE, D.K. CROSS MARG, Maharashtra 400027</Text>
		  <Flex direction="column">
			<Link to="mailto:variantlabels@gmail.com">variantlabels@gmail.com</Link>
			<Link to="tel:+919167036524" >+91 91670 36524</Link>
		  <Link to="tel:+919821336524" >+91 98213 36524</Link>
		  <Link to="tel:40044407" >40044407</Link></Flex>

		  
		  <Text color="#c9c9c9">Mon - Sat. 10:30 AM - 6:30 PM</Text>
				</Flex>
				<Flex direction="column">
					<Heading mt="20%" mb="5px" fontSize="sm" letterSpacing="widest">PRODUCTS</Heading>
					<HeaderLink size="xs"  url="/product/BARCODE PRINTERS" label="BARCODE PRINTERS" color="f5f5f5"/>
            <HeaderLink size="xs" url="/product/MOBILE PRINTER" label="MOBILE PRINTER" />
            <HeaderLink size="xs" url="/product/THERMAL BILL PRINTERS" label="THERMAL BILL PRINTERS" />
            <HeaderLink size="xs" url="/product/BARCODE SCANNERS" label="BARCODE SCANNERS" />
            <HeaderLink size="xs"
              url="/product/THERMAL TRANSFER RIBBONS"
              label="THERMAL TRANSFER RIBBONS"
            />
            <HeaderLink size="xs" url="/product/HAND LABELLERS" label="HAND LABELLERS" />
            {/* <HeaderLink
              url="/product/desktop"
              label="SEQUENTIAL NUMBERING MACHINE"
            /> */}
            <HeaderLink size="xs" url="/product/BARCODE LABELS" label="BARCODE LABELS" />
            <HeaderLink size="xs" url="/product/GUN LABELS" label="GUN LABELS" />
            {/* <HeaderLink url="/product/desktop" label="POS ROLLS" /> */}
            <HeaderLink size="xs" url="/product/PRE INKED STAMPS" label="PRE INKED STAMPS" />
            <HeaderLink size="xs" url="/product/NOTE COUNTING MACHINE" label="NOTE COUNTING MACHINE" />
				</Flex>

			<Flex direction="column" >
			<Heading mt="20%" mb="5px" fontSize="sm" letterSpacing="widest">GET IN TOUCH</Heading>
			<form ref={form} onSubmit={sendEmail}>
      <label>Name</label>
      <Input size="sm" type="text" name="user_name" />
      <label>Email</label>
      <Input size="sm" type="email" name="user_email" />
      <label>Message</label>
      <Input size="sm" name="message" />
      <Input size="sm" type="submit" value="Send" mt="15px" color="#f5f5f5"
          bg="#df0000"
          _hover={{ bg: "#f5f5f5", color: "#df0000" }}/>
    </form>
			</Flex>
			</Grid >
			<Text mt='40px' px="55px" textAlign="center" noOfLines={3}>
				Copyright {new Date().getFullYear()}.
				 Variant Labels and Technologies.
				 All rights reserved.
			</Text>
		</Flex>
	);
};

export default Footer;
