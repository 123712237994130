import {
  Box,
  Flex,
  Heading,
  Button,
  Image,
  Icon,
  Link,
  Text,
} from "@chakra-ui/react";
import { Document, Page, pdfjs } from 'react-pdf';
import { HiArrowRight } from "react-icons/hi";
import { Link as RouterLink } from "react-router-dom";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const ProductCard = ({ url, name, image, text = "View All" }) => {
  const refresh = () => window.location.reload(true)
  
  return (
    <Flex
      justifyContent="space-between"
      marginx="10px"
      as={RouterLink}
      to={url}
      textAlign="center"
      borderRadius="lg"
      bgColor="white"
      flexDirection="column"
      rowGap="4px"
      padding="8px"
      _hover={{
        textDecor: "none",
        transform: "scale(1.05)",
        transitionDuration: "0.5s",
      }}
    >
      <Flex alignItems="center" justifyContent="center">
        <Image
          src={image}
          borderRadius="lg"
          alt={name}
          w="full"
          h="auto"
          objectFit="cover"
        />
      </Flex>

      {/* <Flex py="5" px="4" direction="column" justifyContent="space-between"> */}
      <Heading as="h4" fontSize={["xs", "sm", "md", "lg"]} py="4px">
        {name}
      </Heading>
      <Button 
        letterSpacing="1px"
        fontWeight="200"
        as={RouterLink}
        to={url}
        color="#f5f5f5"
        bg="#df0000"
        fontSize={["xs","xs", "sm", "md", "lg"]}
        _hover={{
          bg: "#f5f5f5",
          boxShadow: "inset 0 0 0 2px #df0000",
          color: "#df0000",
        }}
      >
        {text}
      </Button>
      {/* </Flex> */}
    </Flex>
  );
  
};

export default ProductCard;
