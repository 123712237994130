import { Heading, Box, Flex, Grid } from "@chakra-ui/react";
import React from "react";
import products from "../newproducts";
import ProductCard from "./ProductCard";

const Items = ({ category }) => {
  let prodList = products.filter((product) => product.category == category);
  console.log("prodList=");
  console.log(prodList);
  //   console.log(products);
  //   console.log(products[0].category);
  //   console.log(category);

  return (
    <Grid pos="relative"
      mt="20px"
      templateColumns={["1fr 1fr","1fr 1fr", "1fr 1fr 1fr","1fr 1fr 1fr", "1fr 1fr 1fr 1fr"]}
      gap="30px"
    >
      {prodList.map((prod) => (
        <ProductCard
          key={prod._id}
          name={prod.name}
          image={prod.image}
          url={prod.url}
          text={prod.text?prod.text:"Open Brochure"}
        />
      ))}
    </Grid>
  );
};
export default Items;
