import React from "react";
import { Flex, Image, Heading, Text, Container } from "@chakra-ui/react";
// import barcodeImage from "../../public/images/barcodeImage.png";
const About = () => {
  return (
    <Flex flexDirection="column"   >
      <Image src="/images/barcodeImage.png" />
      <Flex
        flexDirection="column"
        marginY="40px"
        px={["10%", "20%"]}
        alignItems="center"
        justifyContent="center"
      >
        <Heading
          as="h2"
          color="#df0000"
          // textDecor="underline"
          letterSpacing="widest"
        >
          ABOUT US
        </Heading>
        <Text fontSize={["xs","s","md"]} textAlign="justify" mt="20px">
          Variant Labels & Technologies, founded by Daljeet Singh Oberoi in 1994, started its journey by venturing into the hand labeller market in India. With a commitment to excellence and innovation, we quickly became the foremost choice and the undisputed leader in this segment. Our dedication to providing high-quality products and exceptional service set us apart, earning us the trust and loyalty of our customers.</Text>
        <br />
        <Text fontSize={["xs","s","md"]} textAlign="justify">Building on this success, we expanded our horizons and diversified into barcode solutions, offering a comprehensive range of products including barcode printers, scanners, and labels. This strategic move allowed us to cater to the evolving needs of our customers and solidify our position as a one-stop solution provider for all their labeling and identification requirements. Today, we stand at the forefront of the industry, recognized for our unwavering commitment to quality, innovation, and customer satisfaction.
        </Text>
        <br />
        <Text fontSize={["xs","s","md"]} textAlign="justify">
          Looking ahead, our future scope includes further advancements in technology to continue providing cutting-edge solutions. Our strong emphasis on customer relations ensures that we maintain a deep understanding of our clients' needs, fostering long-lasting partnerships built on trust and mutual success.</Text>
      </Flex>
      <Image src="/images/barcodeImage.png" id="products" />
    </Flex>
  );
};

export default About;
