// const products =[
//     {
//       "category": "BARCODE PRINTERS",
//       "brand": "",
//       "name": "DESKTOP PRINTERS",
//       "image": "/images/TSC TE244.jpg",
//       "url": "/product/DESKTOP PRINTERS",
//       "text": "View All"
//     },
//     {
//       "category": "BARCODE PRINTERS",
//       "brand": "",
//       "name": "SEMI-INDUSTRIAL PRINTERS",
//       "image": "/images/TSC TA-220.jpg",
//       "url": "/product/SEMI-INDUSTRIAL PRINTERS",
//       "text": "View All"
//     },
//     {
//       "category": "BARCODE PRINTERS",
//       "brand": "",
//       "name": "INDUSTRIAL PRINTERS",
//       "image": "/images/CITIZEN CL-S 700.jpg",
//       "url": "/product/INDUSTRIAL PRINTERS",
//       "text": "View All"
//     },
//     {
//       "category": "",
//       "brand": "",
//       "name": "",
//       "image": "",
//       "url": "",
//       "text": ""
//     },
//     {
//       "category": "DESKTOP PRINTERS",
//       "brand": "TSC",
//       "name": "TSC TE244",
//       "image": "/images/TSC TE244.jpg",
//       "url": "https://fs.tscprinters.com/system/files/RS-11/te200-en-emea-datasheet-a4-10102017.pdf",
//       "text": ""
//     },
//     {
//       "category": "DESKTOP PRINTERS",
//       "brand": "TSC",
//       "name": "TSC 244PRO",
//       "image": "/images/TSC 244PRO.jpg",
//       "url": "https://fs.tscprinters.com/system/files/RS-11/ttp-244-pro-eng-datasheet-us.pdf",
//       "text": ""
//     },
//     {
//       "category": "SEMI-INDUSTRIAL PRINTERS",
//       "brand": "TSC",
//       "name": "TSC TE210",
//       "image": "/images/TSC TE210.jpg",
//       "url": "https://fs.tscprinters.com/system/files/RS-11/te200-en-emea-datasheet-a4-10102017.pdf",
//       "text": ""
//     },
//     {
//       "category": "SEMI-INDUSTRIAL PRINTERS",
//       "brand": "TSC",
//       "name": "TSC TE310",
//       "image": "/images/TSC TE310.jpg",
//       "url": "https://fs.tscprinters.com/system/files/RS-11/te200-en-emea-datasheet-a4-10102017.pdf",
//       "text": ""
//     },
//     {
//       "category": "SEMI-INDUSTRIAL PRINTERS",
//       "brand": "TSC",
//       "name": "TSC TA-220",
//       "image": "/images/TSC TA-220.jpg",
//       "url": "https://www.dropbox.com/scl/fi/n5rxm7tjsfb1hlf7m9bkx/tsc-ta220.pdf?rlkey=rwn75sypmnu9vkl0xcoczwzxd&dl=0&raw=1",
//       "text": ""
//     },
//     {
//       "category": "SEMI-INDUSTRIAL PRINTERS",
//       "brand": "TSC",
//       "name": "TSC TTP 247",
//       "image": "/images/TSC TTP 247.jpg",
//       "url": "https://fs.tscprinters.com/system/files/RS-11/ttp-247-series-eng-datasheet-print.pdf",
//       "text": ""
//     },
//     {
//       "category": "SEMI-INDUSTRIAL PRINTERS",
//       "brand": "TSC",
//       "name": "TSC TTP 345",
//       "image": "/images/TSC TTP 345.jpg",
//       "url": "https://fs.tscprinters.com/system/files/ttp-244ce_eng_datasheet_050516.pdf",
//       "text": ""
//     },
//     {
//       "category": "SEMI-INDUSTRIAL PRINTERS",
//       "brand": "TSC",
//       "name": "TSC DA220",
//       "image": "/images/TSC DA220.jpg",
//       "url": "https://fs.tscprinters.com/system/files/da_series_en_web_20240108.pdf",
//       "text": ""
//     },
//     {
//       "category": "SEMI-INDUSTRIAL PRINTERS",
//       "brand": "TSC",
//       "name": "TSC DA 310",
//       "image": "/images/TSC DA 310.jpg",
//       "url": "https://fs.tscprinters.com/system/files/da_series_en_web_20240108.pdf",
//       "text": ""
//     },
//     {
//       "category": "DESKTOP PRINTERS",
//       "brand": "HGRT",
//       "name": "HGRT HT300",
//       "image": "/images/HGRT HT300.jpg",
//       "url": "https://www.dropbox.com/scl/fi/x4mp0e4za617eo19hgzpr/HT300_Leaflet_1808.pdf?rlkey=46xflj4f243rigbgxvyui28p5&dl=0&raw=1",
//       "text": ""
//     },
//     {
//       "category": "DESKTOP PRINTERS",
//       "brand": "HGRT",
//       "name": "HGRT HT330",
//       "image": "/images/HGRT HT330.jpg",
//       "url": "https://www.dropbox.com/scl/fi/x4mp0e4za617eo19hgzpr/HT300_Leaflet_1808.pdf?rlkey=46xflj4f243rigbgxvyui28p5&dl=0&raw=1",
//       "text": ""
//     },
//     {
//       "category": "DESKTOP PRINTERS",
//       "brand": "Zebra",
//       "name": "ZEBRA ZD 220",
//       "image": "/images/ZEBRA ZD 220.jpg",
//       "url": "https://www.dropbox.com/scl/fi/uube00fj1q3nacy9p5g4l/zd220-spec-sheet-en-us.pdf?rlkey=e522fswu5lunquz9ctn08ehkg&dl=0&raw=1",
//       "text": ""
//     },
//     {
//       "category": "DESKTOP PRINTERS",
//       "brand": "Zebra",
//       "name": "ZEBRA ZD 230",
//       "image": "/images/ZEBRA ZD 230.jpg",
//       "url": "https://www.dropbox.com/scl/fi/uube00fj1q3nacy9p5g4l/zd220-spec-sheet-en-us.pdf?rlkey=e522fswu5lunquz9ctn08ehkg&dl=0&raw=1",
//       "text": ""
//     },
//     {
//       "category": "DESKTOP PRINTERS",
//       "brand": "Citizen",
//       "name": "CITIZEN CL-E 321",
//       "image": "/images/CITIZEN CL-E 321.jpg",
//       "url": "https://www.dropbox.com/scl/fi/d4kn82mnmg2tymrhsevi9/datasheet-cl-e321-en.pdf?rlkey=iw4nr2tp7ggnfbrktfnd1l72g&dl=0&raw=1",
//       "text": ""
//     },
//     {
//       "category": "DESKTOP PRINTERS",
//       "brand": "Citizen",
//       "name": "CITIZEN CL-E 331",
//       "image": "/images/CITIZEN CL-E 331.jpg",
//       "url": "https://www.dropbox.com/scl/fi/586ner2opca0ngyk2r2vv/datasheet-cl-e331-en.pdf?rlkey=j7t8u1mg9u6nyx2fsg9ks92nk&dl=0&raw=1",
//       "text": ""
//     },
//     {
//       "category": "SEMI-INDUSTRIAL PRINTERS",
//       "brand": "Citizen",
//       "name": "CITIZEN CL-S 621",
//       "image": "/images/CITIZEN CL-S 621.jpg",
//       "url": "https://www.dropbox.com/scl/fi/0cb96d8xkov98nefnrmrl/datasheet-cl-s621-en.pdf?rlkey=hws58a3vsmdge5qpwl3lubs8b&dl=0&raw=1",
//       "text": ""
//     },
//     {
//       "category": "INDUSTRIAL PRINTERS",
//       "brand": "Citizen",
//       "name": "CITIZEN CL-S 700",
//       "image": "/images/CITIZEN CL-S 700.jpg",
//       "url": "https://www.dropbox.com/scl/fi/p04ncn4cg1zo373uwbufl/datasheet-cl-s700-en.pdf?rlkey=f6nufkzlp3is4yj8bggqdm4yn&dl=0&raw=1",
//       "text": ""
//     },
//     {
//       "category": "INDUSTRIAL PRINTERS",
//       "brand": "Citizen",
//       "name": "CITIZEN CL-S6621",
//       "image": "/images/CITIZEN CL-S6621.jpg",
//       "url": "https://www.dropbox.com/scl/fi/f8bq3mr8z9bf80peywe8s/datasheet-cl-s6621-en.pdf?rlkey=ykt4qqbf2tgzirbn2n1t89o51&dl=0&raw=1",
//       "text": ""
//     },
//     {
//       "category": "",
//       "brand": "",
//       "name": "",
//       "image": "",
//       "url": "",
//       "text": ""
//     },
//     {
//       "category": "HAND LABELLERS",
//       "brand": "JOLLY",
//       "name": "JOLLY",
//       "image": "/images/JOLLY.jpg",
//       "url": "https://www.dropbox.com/scl/fi/9796co41ba4jazo86hjho/JOLLY-WEB.pdf?rlkey=1rwa4s5a3qhbllj3tl1maqiyo&dl=0&raw=1",
//       "text": "View All"
//     },
//     {
//       "category": "HAND LABELLERS",
//       "brand": "OPEN DATA",
//       "name": "OPEN DATA",
//       "image": "/images/OPEN DATA.jpg",
//       "url": "https://www.dropbox.com/scl/fi/fqhxkmss7e3vjg19k7o4r/OPEN-DATA-WEB.pdf?rlkey=wr9tldzx22sfwe7r9ldawm56z&dl=0&raw=1",
//       "text": "View All"
//     },
//     {
//       "category": "HAND LABELLERS",
//       "brand": "BLITZ",
//       "name": "BLITZ",
//       "image": "/images/BLITZ.jpg",
//       "url": "https://www.dropbox.com/scl/fi/u0pm8sz5f6c36nf0j76xd/BLITZ-WEB.pdf?rlkey=wlvw9chhkrfrps2rvuv7x01pd&dl=0&raw=1",
//       "text": "View All"
//     },
//     {
//       "category": "HAND LABELLERS",
//       "brand": "BLITZ",
//       "name": "SEQUENTIAL NUMBERING MACHINE",
//       "image": "/images/SEQUENTIAL NUMBERING MACHINE.jpg",
//       "url": "https://www.dropbox.com/scl/fi/1t0g1zb004i2ej61kaof0/BLITZ-SEQUENTIAL-WEB.pdf?rlkey=lhcas98mm73jwwu3xwaht8tq7&dl=0&raw=1",
//       "text": "View All"
//     },
//     {
//       "category": "",
//       "brand": "",
//       "name": "",
//       "image": "",
//       "url": "",
//       "text": ""
//     },
//     {
//       "category": "",
//       "brand": "",
//       "name": "",
//       "image": "",
//       "url": "",
//       "text": ""
//     },
//     {
//       "category": "THERMAL BILL PRINTERS",
//       "brand": "HGRT",
//       "name": "HGRT HGS 030",
//       "image": "/images/HGRT HGS 030.jpg",
//       "url": "https://www.dropbox.com/scl/fi/ve38kfo01lgoc5zw8ujxr/HGS-030-PDF.pdf?rlkey=zypdxvaj4dh6zwvwk8weg07u3&dl=0&raw=1",
//       "text": ""
//     },
//     {
//       "category": "THERMAL BILL PRINTERS",
//       "brand": "HGRT",
//       "name": "HGRT TP 805",
//       "image": "/images/HGRT TP 805.jpg",
//       "url": "https://www.dropbox.com/scl/fi/n0tibr51xqga9y61xqnfm/TP805_Leaflet_1710.pdf?rlkey=goyaxyd81e9ebl9l31jyw6k6m&dl=0&raw=1",
//       "text": ""
//     },
//     {
//       "category": "THERMAL BILL PRINTERS",
//       "brand": "RETSOL",
//       "name": "RETSOL TP-806",
//       "image": "/images/RETSOL TP-806.jpg",
//       "url": "",
//       "text": ""
//     },
//     {
//       "category": "THERMAL BILL PRINTERS",
//       "brand": "RETSOL",
//       "name": "RETSOL RTP-80",
//       "image": "/images/RETSOL RTP-80.jpg",
//       "url": "",
//       "text": ""
//     },
//     {
//       "category": "THERMAL BILL PRINTERS",
//       "brand": "RETSOL",
//       "name": "RETSOL RBT-82U",
//       "image": "/images/RETSOL RBT-82U.jpg",
//       "url": "",
//       "text": ""
//     },
//     {
//       "category": "THERMAL BILL PRINTERS",
//       "brand": "CITIZEN",
//       "name": "CITIZEN CT-S310 III",
//       "image": "/images/CITIZEN CT-S310 III.jpg",
//       "url": "",
//       "text": ""
//     },
//     {
//       "category": "THERMAL BILL PRINTERS",
//       "brand": "SKR",
//       "name": "SKR PREMIUM",
//       "image": "/images/SKR PREMIUM.jpg",
//       "url": "",
//       "text": ""
//     },
//     {
//       "category": "THERMAL BILL PRINTERS",
//       "brand": "SKR",
//       "name": "SKR ULTRA PREMIUM",
//       "image": "/images/SKR ULTRA PREMIUM.jpg",
//       "url": "",
//       "text": ""
//     },
//     {
//       "category": "",
//       "brand": "",
//       "name": "",
//       "image": "",
//       "url": "",
//       "text": ""
//     },
//     {
//       "category": "BARCODE LABELS",
//       "brand": "",
//       "name": "BARCODE LABELS",
//       "image": "/images/BARCODE LABELS.jpg",
//       "url": "https://www.dropbox.com/scl/fi/psdcgachx5jkovolsxydx/BARCODE-LABELS-WEB.pdf?rlkey=iz27t5tt20wrp0f69uz4nw0jj&dl=0&raw=1",
//       "text": "View All"
//     },
//     {
//       "category": "",
//       "brand": "",
//       "name": "",
//       "image": "",
//       "url": "",
//       "text": ""
//     },
//     {
//       "category": "THERMAL TRANSFER RIBBONS",
//       "brand": "",
//       "name": "THERMAL TRANSFER RIBBONS",
//       "image": "/images/THERMAL TRANSFER RIBBONS.jpg",
//       "url": "https://www.dropbox.com/scl/fi/f3rrenuwtqulxk50i2mw6/TTR-WEB.pdf?rlkey=snvlt1hurofcz5gksgfhoaq7b&dl=0&raw=1",
//       "text": "View All"
//     },
//     {
//       "category": "",
//       "brand": "",
//       "name": "",
//       "image": "",
//       "url": "",
//       "text": ""
//     },
//     {
//       "category": "GUN LABELS",
//       "brand": "",
//       "name": "GUN LABELS",
//       "image": "/images/gunlabels.jpg",
//       "url": "",
//       "text": "View All"
//     },
//     {
//       "category": "",
//       "brand": "",
//       "name": "",
//       "image": "",
//       "url": "",
//       "text": ""
//     },
//     {
//       "category": "PRE INKED STAMPS",
//       "brand": "",
//       "name": "PRE INKED STAMPS",
//       "image": "/images/PRE INKED STAMPS.jpg",
//       "url": "https://www.dropbox.com/scl/fi/i26j7avraya978xgrewhc/SMART-STAMPS.pdf?rlkey=anc4xcyo5skb9mwmz15uxtz1x&dl=0&raw=1",
//       "text": "View All"
//     },
//     {
//       "category": "",
//       "brand": "",
//       "name": "",
//       "image": "",
//       "url": "",
//       "text": ""
//     },
//     {
//       "category": "NOTE COUNTING MACHINE",
//       "brand": "",
//       "name": "NOTE COUNTING MACHINE",
//       "image": "/images/NOTE COUNTING MACHINE.jpg",
//       "url": "https://www.dropbox.com/scl/fi/o49kvc8r0xgov9aoqz43g/NOTE-COUNTING-MACHINE-WEB.pdf?rlkey=7d1gxhfbc4v84zhdr5syiruld&dl=0&raw=1",
//       "text": "View All"
//     },
//     {
//       "category": "",
//       "brand": "",
//       "name": "",
//       "image": "",
//       "url": "",
//       "text": ""
//     },
//     {
//       "category": "MOBILE PRINTER",
//       "brand": "HGRT",
//       "name": "HGRT HGS-80ML",
//       "image": "/images/HGRT HGS-80ML.png",
//       "url": "",
//       "text": ""
//     },
//     {
//       "category": "",
//       "brand": "",
//       "name": "",
//       "image": "",
//       "url": "",
//       "text": ""
//     },
//     {
//       "category": "BARCODE SCANNERS",
//       "brand": "HGRT",
//       "name": "HGS 2890 1D",
//       "image": "/images/HGS 2890 1D.jpg",
//       "url": "",
//       "text": ""
//     },
//     {
//       "category": "BARCODE SCANNERS",
//       "brand": "HGRT",
//       "name": "HGS HGS 2020 CTP  1D WIRELESS",
//       "image": "/images/HGS HGS 2020 CTP  1D WIRELESS.jpg",
//       "url": "https://www.dropbox.com/scl/fi/7z5kzto966adhwwucf2it/HGS-2020-BTW.pdf?rlkey=shjdewvmex7v2y1ca7caf7hwd&dl=0https://www.dropbox.com/scl/fi/u0pm8sz5f6c36nf0j76xd/BLITZ-WEB.pdf?rlkey=wlvw9chhkrfrps2rvuv7x01pd&dl=0&raw=1",
//       "text": ""
//     },
//     {
//       "category": "BARCODE SCANNERS",
//       "brand": "HGRT",
//       "name": "HGS 420 2D WIRED",
//       "image": "/images/HGS 420 2D WIRED.jpg",
//       "url": "",
//       "text": ""
//     },
//     {
//       "category": "BARCODE SCANNERS",
//       "brand": "HGRT",
//       "name": "HGS 2818A  2D WIRED",
//       "image": "/images/HGS 2818A  2D WIRED.jpg",
//       "url": "",
//       "text": ""
//     },
//     {
//       "category": "BARCODE SCANNERS",
//       "brand": "HGRT",
//       "name": "HGS 2020BTW 2D WIRELESS",
//       "image": "/images/HGS 2020BTW 2D WIRELESS.jpg",
//       "url": "",
//       "text": ""
//     },
//     {
//       "category": "BARCODE SCANNERS",
//       "brand": "HGRT",
//       "name": "HGS 2818D OMNI DIRECTIONAL",
//       "image": "/images/HGS 2818D OMNI DIRECTIONAL.jpg",
//       "url": "",
//       "text": ""
//     },
//     {
//       "category": "BARCODE SCANNERS",
//       "brand": "HGRT",
//       "name": "HGS 9120 OMNI DIRECTIONAL",
//       "image": "/images/HGS 9120 OMNI DIRECTIONAL.jpg",
//       "url": "",
//       "text": ""
//     },
//     {
//       "category": "BARCODE SCANNERS",
//       "brand": "RETSOL",
//       "name": "RETSOL LS 450",
//       "image": "/images/RETSOL LS 450.jpg",
//       "url": "",
//       "text": ""
//     },
//     {
//       "category": "BARCODE SCANNERS",
//       "brand": "RETSOL",
//       "name": "RETSOL LS 500",
//       "image": "/images/RETSOL LS 500.jpg",
//       "url": "",
//       "text": ""
//     },
//     {
//       "category": "BARCODE SCANNERS",
//       "brand": "RETSOL",
//       "name": "RETSOL D-1020 1D/2D WIRED",
//       "image": "/images/RETSOL D-1020 1D/2D WIRED.jpg",
//       "url": "",
//       "text": ""
//     },
//     {
//       "category": "BARCODE SCANNERS",
//       "brand": "RETSOL",
//       "name": "RETSOL D-2060+",
//       "image": "/images/RETSOL D-2060+.jpg",
//       "url": "",
//       "text": ""
//     },
//     {
//       "category": "BARCODE SCANNERS",
//       "brand": "RETSOL",
//       "name": "RETSOL PD-3000+",
//       "image": "/images/RETSOL PD-3000+.jpg",
//       "url": "",
//       "text": ""
//     },
//     {
//       "category": "BARCODE SCANNERS",
//       "brand": "RETSOL",
//       "name": "RETSOL D-5025+BT",
//       "image": "/images/RETSOL D-5025+BT.jpg",
//       "url": "",
//       "text": ""
//     },
//     {
//       "category": "BARCODE SCANNERS",
//       "brand": "ZEBRA",
//       "name": "ZEBRA LS-1203",
//       "image": "/images/ZEBRA LS-1203.jpg",
//       "url": "",
//       "text": ""
//     },
//     {
//       "category": "BARCODE SCANNERS",
//       "brand": "ZEBRA",
//       "name": "ZEBRA LS-2208",
//       "image": "/images/ZEBRA LS-2208.jpg",
//       "url": "",
//       "text": ""
//     },
//     {
//       "category": "BARCODE SCANNERS",
//       "brand": "ZEBRA",
//       "name": "ZEBRA DS-2208",
//       "image": "/images/ZEBRA DS-2208.jpg",
//       "url": "",
//       "text": ""
//     },
//     {
//       "category": "BARCODE SCANNERS",
//       "brand": "ZEBRA",
//       "name": "ZEBRA LI-4278",
//       "image": "/images/ZEBRA LI-4278.jpg",
//       "url": "",
//       "text": ""
//     },
//     {
//       "category": "BARCODE SCANNERS",
//       "brand": "ZEBRA",
//       "name": "ZEBRA DS-9308",
//       "image": "/images/ZEBRA DS-9308.jpg",
//       "url": "",
//       "text": ""
//     },
    
//     {
//       "category": "CATEGORIES",
//       "brand": "",
//       "name": "BARCODE PRINTERS",
//       "image": "/images/BARCODE PRINTERS.jpg",
//       "url": "/product/BARCODE PRINTERS",
//       "text": ""
//     },
//     {
//       "category": "CATEGORIES",
//       "brand": "",
//       "name": "MOBILE PRINTER",
//       "image": "/images/MOBILE PRINTER.jpg",
//       "url": "/product/MOBILE PRINTER",
//       "text": ""
//     },
//     {
//       "category": "CATEGORIES",
//       "brand": "",
//       "name": "THERMAL BILL PRINTERS",
//       "image": "/images/THERMAL BILL PRINTERS.jpg",
//       "url": "/product/THERMAL BILL PRINTERS",
//       "text": ""
//     },
//     {
//       "category": "CATEGORIES",
//       "brand": "",
//       "name": "BARCODE SCANNERS",
//       "image": "/images/BARCODE SCANNERS.jpg",
//       "url": "/product/BARCODE SCANNERS",
//       "text": ""
//     },
//     {
//       "category": "CATEGORIES",
//       "brand": "",
//       "name": "THERMAL TRANSFER RIBBONS",
//       "image": "/images/THERMAL TRANSFER RIBBONS.jpg",
//       "url": "/product/THERMAL TRANSFER RIBBONS",
//       "text": ""
//     },
//     {
//       "category": "CATEGORIES",
//       "brand": "",
//       "name": "HAND LABELLERS",
//       "image": "/images/HAND LABELLERS.jpg",
//       "url": "/product/HAND LABELLERS",
//       "text": ""
//     },
//     {
//       "category": "CATEGORIES",
//       "brand": "",
//       "name": "BARCODE LABELS",
//       "image": "/images/BARCODE LABELS.jpg",
//       "url": "/product/BARCODE LABELS",
//       "text": ""
//     },
//     {
//       "category": "CATEGORIES",
//       "brand": "",
//       "name": "GUN LABELS",
//       "image": "/images/GUN LABELS.jpg",
//       "url": "/product/GUN LABELS",
//       "text": ""
//     },
//     {
//       "category": "CATEGORIES",
//       "brand": "",
//       "name": "PRE INKED STAMPS",
//       "image": "/images/PRE INKED STAMPS.jpg",
//       "url": "/product/PRE INKED STAMPS",
//       "text": ""
//     },
//     {
//       "category": "CATEGORIES",
//       "brand": "",
//       "name": "NOTE COUNTING MACHINE",
//       "image": "/images/NOTE COUNTING MACHINE.jpg",
//       "url": "/product/NOTE COUNTING MACHINE",
//       "text": ""
//     }
//   ]
const products = 
[
  {
    "category": "BARCODE PRINTERS",
    "brand": "",
    "name": "DESKTOP PRINTERS",
    "image": "/images/TSC TE244.jpg",
    "url": "/product/DESKTOP PRINTERS",
    "text": "View All"
  },
  {
    "category": "BARCODE PRINTERS",
    "brand": "",
    "name": "SEMI-INDUSTRIAL PRINTERS",
    "image": "/images/TSC TA-220.jpg",
    "url": "/product/SEMI-INDUSTRIAL PRINTERS",
    "text": "View All"
  },
  {
    "category": "BARCODE PRINTERS",
    "brand": "",
    "name": "INDUSTRIAL PRINTERS",
    "image": "/images/CITIZEN CL-S 700.jpg",
    "url": "/product/INDUSTRIAL PRINTERS",
    "text": "View All"
  },
  {
    "category": "",
    "brand": "",
    "name": "",
    "image": "",
    "url": "",
    "text": ""
  },
  {
    "category": "DESKTOP PRINTERS",
    "brand": "TSC",
    "name": "TSC TE244",
    "image": "/images/TSC TE244.jpg",
    "url": "https://fs.tscprinters.com/system/files/RS-11/te200-en-emea-datasheet-a4-10102017.pdf",
    "text": ""
  },
  {
    "category": "DESKTOP PRINTERS",
    "brand": "TSC",
    "name": "TSC 244PRO",
    "image": "/images/TSC 244PRO.jpg",
    "url": "https://fs.tscprinters.com/system/files/RS-11/ttp-244-pro-eng-datasheet-us.pdf",
    "text": ""
  },
  {
    "category": "SEMI-INDUSTRIAL PRINTERS",
    "brand": "TSC",
    "name": "TSC TE210",
    "image": "/images/TSC TE210.jpg",
    "url": "https://fs.tscprinters.com/system/files/RS-11/te200-en-emea-datasheet-a4-10102017.pdf",
    "text": ""
  },
  {
    "category": "SEMI-INDUSTRIAL PRINTERS",
    "brand": "TSC",
    "name": "TSC TE310",
    "image": "/images/TSC TE310.jpg",
    "url": "https://fs.tscprinters.com/system/files/RS-11/te200-en-emea-datasheet-a4-10102017.pdf",
    "text": ""
  },
  {
    "category": "SEMI-INDUSTRIAL PRINTERS",
    "brand": "TSC",
    "name": "TSC TA-220",
    "image": "/images/TSC TA-220.jpg",
    "url": "https://www.dropbox.com/scl/fi/n5rxm7tjsfb1hlf7m9bkx/tsc-ta220.pdf?rlkey=rwn75sypmnu9vkl0xcoczwzxd&dl=0&raw=1",
    "text": ""
  },
  {
    "category": "SEMI-INDUSTRIAL PRINTERS",
    "brand": "TSC",
    "name": "TSC TTP 247",
    "image": "/images/TSC TTP 247.jpg",
    "url": "https://fs.tscprinters.com/system/files/RS-11/ttp-247-series-eng-datasheet-print.pdf",
    "text": ""
  },
  {
    "category": "SEMI-INDUSTRIAL PRINTERS",
    "brand": "TSC",
    "name": "TSC TTP 345",
    "image": "/images/TSC TTP 345.jpg",
    "url": "https://fs.tscprinters.com/system/files/ttp-244ce_eng_datasheet_050516.pdf",
    "text": ""
  },
  {
    "category": "SEMI-INDUSTRIAL PRINTERS",
    "brand": "TSC",
    "name": "TSC DA220",
    "image": "/images/TSC DA220.jpg",
    "url": "https://fs.tscprinters.com/system/files/da_series_en_web_20240108.pdf",
    "text": ""
  },
  {
    "category": "SEMI-INDUSTRIAL PRINTERS",
    "brand": "TSC",
    "name": "TSC DA 310",
    "image": "/images/TSC DA 310.jpg",
    "url": "https://fs.tscprinters.com/system/files/da_series_en_web_20240108.pdf",
    "text": ""
  },
  {
    "category": "DESKTOP PRINTERS",
    "brand": "HGRT",
    "name": "HGRT HT300",
    "image": "/images/HGRT HT300.jpg",
    "url": "https://www.dropbox.com/scl/fi/x4mp0e4za617eo19hgzpr/HT300_Leaflet_1808.pdf?rlkey=46xflj4f243rigbgxvyui28p5&dl=0&raw=1",
    "text": ""
  },
  {
    "category": "DESKTOP PRINTERS",
    "brand": "HGRT",
    "name": "HGRT HT330",
    "image": "/images/HGRT HT330.jpg",
    "url": "https://www.dropbox.com/scl/fi/x4mp0e4za617eo19hgzpr/HT300_Leaflet_1808.pdf?rlkey=46xflj4f243rigbgxvyui28p5&dl=0&raw=1",
    "text": ""
  },
  {
    "category": "DESKTOP PRINTERS",
    "brand": "Zebra",
    "name": "ZEBRA ZD 220",
    "image": "/images/ZEBRA ZD 220.jpg",
    "url": "https://www.dropbox.com/scl/fi/uube00fj1q3nacy9p5g4l/zd220-spec-sheet-en-us.pdf?rlkey=e522fswu5lunquz9ctn08ehkg&dl=0&raw=1",
    "text": ""
  },
  {
    "category": "DESKTOP PRINTERS",
    "brand": "Zebra",
    "name": "ZEBRA ZD 230",
    "image": "/images/ZEBRA ZD 230.jpg",
    "url": "https://www.dropbox.com/scl/fi/uube00fj1q3nacy9p5g4l/zd220-spec-sheet-en-us.pdf?rlkey=e522fswu5lunquz9ctn08ehkg&dl=0&raw=1",
    "text": ""
  },
  {
    "category": "DESKTOP PRINTERS",
    "brand": "Citizen",
    "name": "CITIZEN CL-E 321",
    "image": "/images/CITIZEN CL-E 321.jpg",
    "url": "https://www.dropbox.com/scl/fi/d4kn82mnmg2tymrhsevi9/datasheet-cl-e321-en.pdf?rlkey=iw4nr2tp7ggnfbrktfnd1l72g&dl=0&raw=1",
    "text": ""
  },
  {
    "category": "DESKTOP PRINTERS",
    "brand": "Citizen",
    "name": "CITIZEN CL-E 331",
    "image": "/images/CITIZEN CL-E 331.jpg",
    "url": "https://www.dropbox.com/scl/fi/586ner2opca0ngyk2r2vv/datasheet-cl-e331-en.pdf?rlkey=j7t8u1mg9u6nyx2fsg9ks92nk&dl=0&raw=1",
    "text": ""
  },
  {
    "category": "SEMI-INDUSTRIAL PRINTERS",
    "brand": "Citizen",
    "name": "CITIZEN CL-S 621",
    "image": "/images/CITIZEN CL-S 621.jpg",
    "url": "https://www.dropbox.com/scl/fi/0cb96d8xkov98nefnrmrl/datasheet-cl-s621-en.pdf?rlkey=hws58a3vsmdge5qpwl3lubs8b&dl=0&raw=1",
    "text": ""
  },
  {
    "category": "INDUSTRIAL PRINTERS",
    "brand": "Citizen",
    "name": "CITIZEN CL-S 700",
    "image": "/images/CITIZEN CL-S 700.jpg",
    "url": "https://www.dropbox.com/scl/fi/p04ncn4cg1zo373uwbufl/datasheet-cl-s700-en.pdf?rlkey=f6nufkzlp3is4yj8bggqdm4yn&dl=0&raw=1",
    "text": ""
  },
  {
    "category": "INDUSTRIAL PRINTERS",
    "brand": "Citizen",
    "name": "CITIZEN CL-S6621",
    "image": "/images/CITIZEN CL-S6621.jpg",
    "url": "https://www.dropbox.com/scl/fi/f8bq3mr8z9bf80peywe8s/datasheet-cl-s6621-en.pdf?rlkey=ykt4qqbf2tgzirbn2n1t89o51&dl=0&raw=1",
    "text": ""
  },
  {
    "category": "",
    "brand": "",
    "name": "",
    "image": "",
    "url": "",
    "text": ""
  },
  {
    "category": "HAND LABELLERS",
    "brand": "JOLLY",
    "name": "JOLLY",
    "image": "/images/JOLLY.jpg",
    "url": "https://www.dropbox.com/scl/fi/9796co41ba4jazo86hjho/JOLLY-WEB.pdf?rlkey=1rwa4s5a3qhbllj3tl1maqiyo&dl=0&raw=1",
    "text": "View All"
  },
  {
    "category": "HAND LABELLERS",
    "brand": "OPEN DATA",
    "name": "OPEN DATA",
    "image": "/images/OPEN DATA.jpg",
    "url": "https://www.dropbox.com/scl/fi/ayx0quay4t8uzvwyw3ks7/OPEN-DATA-WEB.pdf?rlkey=jy87fhprl4spd6m5n368x2zy6&dl=0&raw=1",
    "text": "View All"
  },
  {
    "category": "HAND LABELLERS",
    "brand": "BLITZ",
    "name": "BLITZ",
    "image": "/images/BLITZ.jpg",
    "url": "https://www.dropbox.com/scl/fi/ibr6e7dltbrmqd86305bo/BLITZ-WEB.pdf?rlkey=shqsb9eix0occpvbqcysnvav2&dl=0&raw=1",
    "text": "View All"
  },
  {
    "category": "HAND LABELLERS",
    "brand": "BLITZ",
    "name": "SEQUENTIAL NUMBERING MACHINE",
    "image": "/images/SEQUENTIAL NUMBERING MACHINE.jpg",
    "url": "https://www.dropbox.com/scl/fi/kjxzo8a0vxv01ogcm5ggk/BLITZ-SEQUENTIAL-WEB.pdf?rlkey=ggq6efbi89r8nqrzpga9gr98j&dl=0&raw=1",
    "text": "View All"
  },
  {
    "category": "",
    "brand": "",
    "name": "",
    "image": "",
    "url": "",
    "text": ""
  },
  {
    "category": "",
    "brand": "",
    "name": "",
    "image": "",
    "url": "",
    "text": ""
  },
  {
    "category": "THERMAL BILL PRINTERS",
    "brand": "RETSOL",
    "name": "RETSOL TP-806",
    "image": "/images/RETSOL TP-806.jpg",
    "url": "https://www.dropbox.com/scl/fi/r0t9xztxt4rnf4pz3y7pv/TP806.pdf?rlkey=08ubb436d6guumlmmqe0i5raw&st=cvlb8axi&dl=0&raw=1",
    "text": ""
  },
  {
    "category": "THERMAL BILL PRINTERS",
    "brand": "RETSOL",
    "name": "RETSOL RTP-80",
    "image": "/images/RETSOL RTP-80.jpg",
    "url": "https://www.dropbox.com/scl/fi/1v5p0bzmws89x190j93hc/Retsol-RTP-80.pdf?rlkey=cd741gsq9axbogl1brnut48yf&st=djl3l8bn&dl=0&raw=1",
    "text": ""
  },
  {
    "category": "THERMAL BILL PRINTERS",
    "brand": "RETSOL",
    "name": "RETSOL RBT-82U",
    "image": "/images/RETSOL RBT-82U.jpg",
    "url": "https://www.dropbox.com/scl/fi/ex6a1vm0myuabsm2i1pnq/Retsol-RTP-82UE.pdf?rlkey=6s8dl74kv4y76lfxcg8d57m8h&st=wwinwixw&dl=0&raw=1",
    "text": ""
  },
  {
    "category": "THERMAL BILL PRINTERS",
    "brand": "HGRT",
    "name": "HGRT HGS 030",
    "image": "/images/HGRT HGS 030.jpg",
    "url": "https://www.dropbox.com/scl/fi/ve38kfo01lgoc5zw8ujxr/HGS-030-PDF.pdf?rlkey=zypdxvaj4dh6zwvwk8weg07u3&dl=0&raw=1",
    "text": ""
  },
  {
    "category": "THERMAL BILL PRINTERS",
    "brand": "HGRT",
    "name": "HGRT TP 805",
    "image": "/images/HGRT TP 805.jpg",
    "url": "https://www.dropbox.com/scl/fi/n0tibr51xqga9y61xqnfm/TP805_Leaflet_1710.pdf?rlkey=goyaxyd81e9ebl9l31jyw6k6m&dl=0&raw=1",
    "text": ""
  },
  {
    "category": "THERMAL BILL PRINTERS",
    "brand": "CITIZEN",
    "name": "CITIZEN CT-S310 III",
    "image": "/images/CITIZEN CT-S310 III.jpg",
    "url": "https://www.dropbox.com/scl/fi/849i0tvqz0mdtewaxlatg/datasheet-ct-s310ii-en.pdf?rlkey=vtfbi1mz5lr9kdufneratz63t&st=4sldnvjr&dl=0&raw=1",
    "text": ""
  },
  {
    "category": "THERMAL BILL PRINTERS",
    "brand": "SKR",
    "name": "SKR PREMIUM",
    "image": "/images/SKR.jpg",
    "url": "https://www.dropbox.com/scl/fi/z74wuwbznhuhgwncjesn2/SKR-premium.pdf?rlkey=bfb6cru8v9un7e3iiv0y1nxgl&st=qgk50ge2&dl=0&raw=1",
    "text": ""
  },
  {
    "category": "THERMAL BILL PRINTERS",
    "brand": "SKR",
    "name": "SKR ULTRA PREMIUM",
    "image": "/images/SKR.jpg",
    "url": "https://www.dropbox.com/scl/fi/h939oahb91jpcrd34r9ha/SKR-ultra-premium.pdf?rlkey=5enclxwe25ytgtexzrxnkm2ow&st=cv096cik&dl=0&raw=1",
    "text": ""
  },
  {
    "category": "",
    "brand": "",
    "name": "",
    "image": "",
    "url": "",
    "text": ""
  },
  {
    "category": "BARCODE LABELS",
    "brand": "",
    "name": "BARCODE LABELS",
    "image": "/images/BARCODE LABELS.jpg",
    "url": "https://www.dropbox.com/scl/fi/s034n2q7kdjk1rolusncf/barcode-label-web.pdf?rlkey=7q7t5f13vbjdacm6ewuhim5qb&dl=0&raw=1",
    "text": "View All"
  },
  {
    "category": "",
    "brand": "",
    "name": "",
    "image": "",
    "url": "",
    "text": ""
  },
  {
    "category": "THERMAL TRANSFER RIBBONS",
    "brand": "",
    "name": "THERMAL TRANSFER RIBBONS",
    "image": "/images/THERMAL TRANSFER RIBBONS.jpg",
    "url": "https://www.dropbox.com/scl/fi/ikmx18vp9g5cwwcfuzscu/TTR-WEB.pdf?rlkey=b4idiawsec9mucja7ppcqlwpo&dl=0&raw=1",
    "text": "View All"
  },
  {
    "category": "",
    "brand": "",
    "name": "",
    "image": "",
    "url": "",
    "text": ""
  },
  {
    "category": "GUN LABELS",
    "brand": "",
    "name": "GUN LABELS",
    "image": "/images/GUN LABELS.jpg",
    "url": "https://www.dropbox.com/scl/fi/h622h6vggmcjfm7847k4h/BARCODE-LABELS-WEB.pdf?rlkey=kyo62x72hunlqdgkn6xfs7smm&dl=0&raw=1",
    "text": "View All"
  },
  {
    "category": "",
    "brand": "",
    "name": "",
    "image": "",
    "url": "",
    "text": ""
  },
  {
    "category": "PRE INKED STAMPS",
    "brand": "",
    "name": "PRE INKED STAMPS",
    "image": "/images/PRE INKED STAMPS.jpg",
    "url": "https://www.dropbox.com/scl/fi/ce9w3clhuxy1cwhhiz37s/SMART-STAMPS.pdf?rlkey=dig15del4r5r092byp33g6gj0&dl=0&raw=1",
    "text": "View All"
  },
  {
    "category": "",
    "brand": "",
    "name": "",
    "image": "",
    "url": "",
    "text": ""
  },
  {
    "category": "NOTE COUNTING MACHINE",
    "brand": "",
    "name": "NOTE COUNTING MACHINE",
    "image": "/images/NOTE COUNTING MACHINE.jpg",
    "url": "https://www.dropbox.com/scl/fi/g29drbsb9rcvz63wtmdx5/NOTE-COUNTING-MACHINE-WEB.pdf?rlkey=zvfgcovsyfr2icy7szde42wbf&dl=0&raw=1",
    "text": "View All"
  },
  {
    "category": "",
    "brand": "",
    "name": "",
    "image": "",
    "url": "",
    "text": ""
  },
  {
    "category": "MOBILE PRINTER",
    "brand": "HGRT",
    "name": "HGRT HGS-80ML",
    "image": "/images/HGRT HGS-80ML.png",
    "url": "https://www.dropbox.com/scl/fi/sk6j42dmvj9y7elheov53/HGRT-HGS-80ML.JPG?rlkey=s4eu2a5arvxrbdfq6ff6yvzdw&st=odg6kim9&dl=0&raw=1",
    "text": ""
  },
  {
    "category": "",
    "brand": "",
    "name": "",
    "image": "",
    "url": "",
    "text": ""
  },
  {
    "category": "BARCODE SCANNERS",
    "brand": "RETSOL",
    "name": "RETSOL LS 450",
    "image": "/images/RETSOL LS 450.jpg",
    "url": "https://www.dropbox.com/scl/fi/auntut8z5q2apu5a9fu1o/LS450-Spec-Sheet.pdf?rlkey=8bpba0ckealqm45rj5bn0h22w&st=umk5num4&dl=0&raw=1",
    "text": ""
  },
  {
    "category": "BARCODE SCANNERS",
    "brand": "RETSOL",
    "name": "RETSOL LS 500",
    "image": "/images/RETSOL LS 500.jpg",
    "url": "https://www.dropbox.com/scl/fi/njpb6xlmphgzf8ze7c4uc/LS500-SPECSHEET.pdf?rlkey=v85z3f8gp8niebe22xwgax0ic&st=0bf3ebmz&dl=0&raw=1",
    "text": ""
  },
  {
    "category": "BARCODE SCANNERS",
    "brand": "RETSOL",
    "name": "RETSOL D-1020 1D/2D WIRED",
    "image": "/images/RETSOL D1020 1D2D WIRED.jpg",
    "url": "https://www.dropbox.com/scl/fi/i728mjaslpnmj0d4c87r5/Retsol-D-1020N-2D-Barcode-Scanner.pdf?rlkey=col04i232uh8qpcppfoe7irba&st=4dbqf6zp&dl=0&raw=1",
    "text": ""
  },
  {
    "category": "BARCODE SCANNERS",
    "brand": "RETSOL",
    "name": "RETSOL D-2060+",
    "image": "/images/RETSOL D-2060+.jpg",
    "url": "https://www.dropbox.com/scl/fi/8yj4sptcu9s6i6n3rn3bb/Retsol-D-2060N-Barcode-Scanner-08042023.pdf?rlkey=xrkejx3jhitcyy4pog0ifzp03&st=k3j7p6dc&dl=0&raw=1",
    "text": ""
  },
  {
    "category": "BARCODE SCANNERS",
    "brand": "RETSOL",
    "name": "RETSOL PD-3000+",
    "image": "/images/RETSOL PD-3000+.jpg",
    "url": "https://www.dropbox.com/scl/fi/gjxepyrvkxza7owwfckr7/RETSOL-PD-3000-Presentation-Scanner.pdf?rlkey=zvn45mtwu96s8cjng4qb8sgwa&st=u5nk97rp&dl=0&raw=1",
    "text": ""
  },
  {
    "category": "BARCODE SCANNERS",
    "brand": "RETSOL",
    "name": "RETSOL D-5025+BT",
    "image": "/images/RETSOL D-5025+BT.jpg",
    "url": "https://www.dropbox.com/scl/fi/wzzy0jiz5vtdonzad0f1s/RETSOL-D5025BT.pdf?rlkey=y4on2v3k7je66aanuxbrycdi4&st=1k8lno3g&dl=0&raw=1",
    "text": ""
  },
  {
    "category": "BARCODE SCANNERS",
    "brand": "HGRT",
    "name": "HGS 2890 1D",
    "image": "/images/HGS 2890 1D.jpg",
    "url": "https://www.dropbox.com/scl/fi/ca92hgb2rf6p8zfenq4yq/HGS-2890-SPEC.pdf?rlkey=xqqztc1t6n20kb9htga1y9dos&st=qjw0jcwu&dl=0&raw=1",
    "text": ""
  },
  {
    "category": "BARCODE SCANNERS",
    "brand": "HGRT",
    "name": "HGS HGS 2020 CTP  1D WIRELESS",
    "image": "/images/HGS HGS 2020 CTP  1D WIRELESS.jpg",
    "url": "https://www.dropbox.com/scl/fi/7z5kzto966adhwwucf2it/HGS-2020-BTW.pdf?rlkey=shjdewvmex7v2y1ca7caf7hwd&dl=0https://www.dropbox.com/scl/fi/u0pm8sz5f6c36nf0j76xd/BLITZ-WEB.pdf?rlkey=wlvw9chhkrfrps2rvuv7x01pd&dl=0&raw=1",
    "text": ""
  },
  {
    "category": "BARCODE SCANNERS",
    "brand": "HGRT",
    "name": "HGS 420 2D WIRED",
    "image": "/images/HGS 420 2D WIRED.jpg",
    "url": "https://www.dropbox.com/scl/fi/mi2ejxol4qzsicmxoum5x/HGS-420-J.pdf?rlkey=oa058upok53cwtlv8or1517p3&st=2abx36xr&dl=0&raw=1",
    "text": ""
  },
  {
    "category": "BARCODE SCANNERS",
    "brand": "HGRT",
    "name": "HGS 2818A  2D WIRED",
    "image": "/images/HGS 2818A  2D WIRED.jpg",
    "url": "https://www.dropbox.com/scl/fi/y3lndo6f1mfws9pqz4rw0/HGS2818A.pdf?rlkey=yveoexqz1pujzoupv7laehew9&dl=0&raw=1",
    "text": ""
  },
  {
    "category": "BARCODE SCANNERS",
    "brand": "HGRT",
    "name": "HGS 2020BTW 2D WIRELESS",
    "image": "/images/HGS 2020BTW 2D WIRELESS.jpg",
    "url": "https://www.dropbox.com/scl/fi/7z5kzto966adhwwucf2it/HGS-2020-BTW.pdf?rlkey=shjdewvmex7v2y1ca7caf7hwd&dl=0&raw=1",
    "text": ""
  },
  {
    "category": "BARCODE SCANNERS",
    "brand": "HGRT",
    "name": "HGS 2818D OMNI DIRECTIONAL",
    "image": "/images/HGS 2818D OMNI DIRECTIONAL.jpg",
    "url": "https://www.dropbox.com/scl/fi/4u6xz3v1sxfvb9962qj4d/HGS-2818D.pdf?rlkey=rfnw0xwdw8rz0j48g2umsfanv&dl=0&raw=1",
    "text": ""
  },
  {
    "category": "BARCODE SCANNERS",
    "brand": "HGRT",
    "name": "HGS 9120 OMNI DIRECTIONAL",
    "image": "/images/HGS 9120 OMNI DIRECTIONAL.jpg",
    "url": "https://www.dropbox.com/scl/fi/u96lca7amtw91viaotls8/HGRT-HGS-9120.pdf?rlkey=nkvq8lwjitiyjeuqwatx4ecaw&st=6icygrqu&dl=0&raw=1",
    "text": ""
  },
  {
    "category": "BARCODE SCANNERS",
    "brand": "ZEBRA",
    "name": "ZEBRA LS-1203",
    "image": "/images/ZEBRA LS-1203.jpg",
    "url": "https://www.dropbox.com/scl/fi/iuovjujp0x6em5slkjd7n/ls1203-spec-sheet-en-gb.pdf?rlkey=c8tl4prj2fmb1i73juuetfymm&st=qooan5xa&dl=0&raw=1",
    "text": ""
  },
  {
    "category": "BARCODE SCANNERS",
    "brand": "ZEBRA",
    "name": "ZEBRA LS-2208",
    "image": "/images/ZEBRA LS-2208.jpg",
    "url": "https://www.dropbox.com/scl/fi/tizkmumbvkeuu8oilo3l6/ls2208-spec-sheet-en-us.pdf?rlkey=5n3sbjxvrjbxffdmo1uxzk56r&st=rznt5u50&dl=0&raw=1",
    "text": ""
  },
  {
    "category": "BARCODE SCANNERS",
    "brand": "ZEBRA",
    "name": "ZEBRA DS-2208",
    "image": "/images/ZEBRA DS-2208.jpg",
    "url": "https://www.dropbox.com/scl/fi/84sctc7wrxi977zec0s21/ds2200-series-spec-sheet-en-us.pdf?rlkey=kbzvm0d9w8trpc6fpryr2c6ak&st=xj7vx34a&dl=0&raw=1",
    "text": ""
  },
  {
    "category": "BARCODE SCANNERS",
    "brand": "ZEBRA",
    "name": "ZEBRA LI-4278",
    "image": "/images/ZEBRA LI-4278.jpg",
    "url": "https://www.dropbox.com/scl/fi/lv8tv27ug8fctox3ek13y/li4278-spec-sheet-en-gb.pdf?rlkey=l93iilusfr5327mfpdnts4ag8&st=paxnmjur&dl=0&raw=1",
    "text": ""
  },
  {
    "category": "BARCODE SCANNERS",
    "brand": "ZEBRA",
    "name": "ZEBRA DS-9308",
    "image": "/images/ZEBRA DS-9308.jpg",
    "url": "https://www.dropbox.com/scl/fi/bp79uig4q2ex4jfhoska7/ds9300-series-spec-sheet-en-us.pdf?rlkey=talviud71e5agh5gm45le5eu8&st=t3aoqv2h&dl=0&raw=1",
    "text": ""
  },
  {
    "category": "",
    "brand": "",
    "name": "",
    "image": "",
    "url": "",
    "text": ""
  },
  {
    "category": "",
    "brand": "",
    "name": "",
    "image": "",
    "url": "",
    "text": ""
  },
  {
    "category": "",
    "brand": "",
    "name": "",
    "image": "",
    "url": "",
    "text": ""
  },
  {
    "category": "",
    "brand": "",
    "name": "",
    "image": "",
    "url": "",
    "text": ""
  },
  {
    "category": "",
    "brand": "",
    "name": "",
    "image": "",
    "url": "",
    "text": ""
  },
  {
    "category": "",
    "brand": "",
    "name": "",
    "image": "",
    "url": "",
    "text": ""
  },
  {
    "category": "",
    "brand": "",
    "name": "",
    "image": "",
    "url": "",
    "text": ""
  },
  {
    "category": "",
    "brand": "",
    "name": "",
    "image": "",
    "url": "",
    "text": ""
  },
  {
    "category": "",
    "brand": "",
    "name": "",
    "image": "",
    "url": "",
    "text": ""
  },
  {
    "category": "",
    "brand": "",
    "name": "",
    "image": "",
    "url": "",
    "text": ""
  },
  {
    "category": "CATEGORIES",
    "brand": "",
    "name": "BARCODE PRINTERS",
    "image": "/images/BARCODE PRINTERS.jpg",
    "url": "/product/BARCODE PRINTERS",
    "text": ""
  },
  {
    "category": "CATEGORIES",
    "brand": "",
    "name": "MOBILE PRINTER",
    "image": "/images/MOBILE PRINTER.jpg",
    "url": "/product/MOBILE PRINTER",
    "text": ""
  },
  {
    "category": "CATEGORIES",
    "brand": "",
    "name": "THERMAL BILL PRINTERS",
    "image": "/images/THERMAL BILL PRINTERS.jpg",
    "url": "/product/THERMAL BILL PRINTERS",
    "text": ""
  },
  {
    "category": "CATEGORIES",
    "brand": "",
    "name": "BARCODE SCANNERS",
    "image": "/images/BARCODE SCANNERS.jpg",
    "url": "/product/BARCODE SCANNERS",
    "text": ""
  },
  {
    "category": "CATEGORIES",
    "brand": "",
    "name": "THERMAL TRANSFER RIBBONS",
    "image": "/images/THERMAL TRANSFER RIBBONS.jpg",
    "url": "/product/THERMAL TRANSFER RIBBONS",
    "text": ""
  },
  {
    "category": "CATEGORIES",
    "brand": "",
    "name": "HAND LABELLERS",
    "image": "/images/HAND LABELLERS.jpg",
    "url": "/product/HAND LABELLERS",
    "text": ""
  },
  {
    "category": "CATEGORIES",
    "brand": "",
    "name": "BARCODE LABELS",
    "image": "/images/BARCODE LABELS.jpg",
    "url": "/product/BARCODE LABELS",
    "text": ""
  },
  {
    "category": "CATEGORIES",
    "brand": "",
    "name": "GUN LABELS",
    "image": "/images/GUN LABELS.jpg",
    "url": "/product/GUN LABELS",
    "text": ""
  },
  {
    "category": "CATEGORIES",
    "brand": "",
    "name": "PRE INKED STAMPS",
    "image": "/images/PRE INKED STAMPS.jpg",
    "url": "/product/PRE INKED STAMPS",
    "text": ""
  },
  {
    "category": "CATEGORIES",
    "brand": "",
    "name": "NOTE COUNTING MACHINE",
    "image": "/images/NOTE COUNTING MACHINE.jpg",
    "url": "/product/NOTE COUNTING MACHINE",
    "text": ""
  },
  {
    "category": "",
    "brand": "",
    "name": "",
    "image": "",
    "url": "",
    "text": ""
  },
  {
    "category": "",
    "brand": "",
    "name": "",
    "image": "",
    "url": "",
    "text": ""
  },
  {
    "category": "ACCESSORIES",
    "brand": "",
    "name": "PRINTER HEAD FOR ALL PRINTERS",
    "image": "/images/PRINTER HEAD FOR ALL PRINTERS.jpg",
    "url": "https://www.dropbox.com/scl/fi/itm68rqu8ghvm1d8s9bly/PRINT-HEAD-WEB-F.pdf?rlkey=nrfmncu9x8abs9tb1xtf883ix&dl=0&raw=1",
    "text": ""
  },
  {
    "category": "ACCESSORIES",
    "brand": "",
    "name": "MOTHERBOARD FOR ALL PRINTERS",
    "image": "/images/MOTHERBOARD FOR ALL PRINTERS.jpg",
    "url": "",
    "text": ""
  },
  {
    "category": "ACCESSORIES",
    "brand": "",
    "name": "LABEL REWINDER",
    "image": "/images/LABEL REWINDER.jpg",
    "url": "",
    "text": ""
  },
  {
    "category": "",
    "brand": "",
    "name": "EXTERNAL HOLDER FOR LABELS",
    "image": "/images/EXTERNAL HOLDER FOR LABELS.jpg",
    "url": "",
    "text": ""
  }
]

  export default products;