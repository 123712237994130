import { Flex, Heading, Box, Menu } from "@chakra-ui/react";
import React from "react";
import { useParams } from "react-router-dom";
import Items from "../components/Items";
import LinkMenu from "../components/LinkMenu";
import Header from "../components/Header";
import Whatsapp from "../components/Whatsapp";
import Footer from "../components/Footer";

const ProductScreen = () => {
  const { category } = useParams();
  const categoryName  = category.split(' ')
  .map(w => w[0].toUpperCase() + w.substring(1).toLowerCase())
  .join(' ');
  return (
    // < >
    //   <LinkMenu />
    //   <Flex
    //    px="40px"  mt="120px" flexDirection="column"><Heading>{categoryName}</Heading>
    //   {/* <Box w="500px" h="200px" bg="red"></Box> */}
    //   <Items category={category}></Items></Flex>
    // </>

    <>
    <Header></Header>
    <Whatsapp/>
    <Flex dir="row">
      <LinkMenu ></LinkMenu>
      <Flex mx="20px" flexDir="column" >
      <Heading mt={{ base: "190px" , lg: "110px" }}>{categoryName}</Heading>
      <Items  category={category}></Items>
      </Flex>
    </Flex>
    <Footer></Footer>
    </>
  );
};

export default ProductScreen;
