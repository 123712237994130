import { Icon, Link } from "@chakra-ui/react";
// import { Link as RouterLink } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import { Link as ScrollLink } from "react-scroll";

const HeaderLink = ({ url, label, color, size="15px"}) => {
  return (
    <Link
      as={HashLink}
      to={url}
      fontSize={size}
      smooth="true"
      letterSpacing="widest"
      textTransform="uppercase"
      mb={{ base: "2", md: "0" }}
      mr="5"
      display="flex"
      alignItems="center"
      color={color ? color : "333333"}
      _hover={{ textDecor: "", fontWeight: "600", color: "#df0000" }}
    >
      {label}
    </Link>
  );
};

export default HeaderLink;
